'use client'

import { CSSProperties, useEffect } from 'react'
import { useIsAppControl } from '@store/isApp'
import pxToRem from '@utils/pxToRem'
import { Box } from '@mui/joy'

interface BizboardAdFitProps {
	type: 'home' | 'article' | 'program' | 'live'
	style?: CSSProperties
}

const AD_TYPE = {
	home: {
		I: 'DAN-hVf8eWbJPEI3RtkH',
		A: 'DAN-3G3imUOs8kWNLlC1',
	},
	article: {
		I: 'DAN-RUHRbamZEX6Bkdms',
		A: 'DAN-twlkVEyBgcjQj53T',
	},
	program: {
		I: 'DAN-UhtePCj5QXfGoW5S',
		A: 'DAN-JOMRYYA2P2QgTags',
	},
	live: {
		I: 'DAN-tlJyIezMDc53QtCD',
		A: 'DAN-4LKnSQQAPfklcrlM',
	},
}

const BizboardAdFit = ({ type, style }: BizboardAdFitProps) => {
	const { isApp, appInfo } = useIsAppControl()

	const AD_KEY = AD_TYPE[type][appInfo?.DType === 'I' ? 'I' : 'A']

	const scriptLoad = () => {
		const script = document.createElement('script')
		script.id = `kakao_bizboard_adfit_${AD_KEY}`
		script.src = '//t1.daumcdn.net/kas/static/ba.min.js'
		script.type = 'text/javascript'
		document.head.appendChild(script)
	}

	useEffect(() => {
		if (!isApp) {
			return
		}

		scriptLoad()
	}, [isApp])

	if (!isApp) {
		return null
	}

	return (
		<Box
			style={{
				width: '100%',
				marginTop: pxToRem(48),
				overflow: 'hidden',
				...style,
			}}
			sx={(theme) => ({
				[theme.breakpoints.up(441)]: {
					display: 'none',
				},
			})}
		>
			<ins
				className="kakao_ad_area"
				data-ad-unit={AD_KEY}
				style={{ display: 'none', borderRadius: 8 }}
			/>
		</Box>
	)
}

export default BizboardAdFit
